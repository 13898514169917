<template>
  <div class="container">
    <div v-if="loading" class="ml-4 loader">
      <Loader></Loader>
    </div>
  </div>
</template>
<script>
import Loader from '../components/loaders/Loader'

export default {
  name: 'Dashboard',
  components: {
    Loader
  },
  data() {
    return {
      loading: true
    }
  },
  mounted() {
    this.loading = false
    this.$router.push({name: "Login"});
  }
}
</script>

<style lang="scss" scoped>
.loader {
  position: relative;
  top: 110px;
  left: 5px;
  display: table;
  align-items: center;
  width: calc(100% - 59px);
  height: 100%;
  min-height: 450px;
  max-height: 450px;
  background-color: #ffffff;
  border: 1px solid #707070;
  border-radius: 4px;
}
</style>

